import React, { createElement } from 'react';
import { Transition } from 'react-transition-group';
// import createHistory from 'history/createBrowserHistory';

import getTransitionStyle from './src/utils/getTransitionStyle';

const timeout = 250;
const historyExitingEventType = `history::exiting`;

// const getUserConfirmation = (pathname, callback) => {
//   const event = new CustomEvent(historyExitingEventType, {
//     detail: { pathname },
//   });
//   window.dispatchEvent(event);
//   setTimeout(() => {
//     callback(true);
//   }, timeout);
// };
// const history = createHistory({ getUserConfirmation });
// // block must return a string to conform
// history.block((location, action) => location.pathname);
// // export const replaceHistory = () => history;

class ReplaceComponentRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { exiting: false, nextPageResources: {} };
    this.listenerHandler = this.listenerHandler.bind(this);
  }

  listenerHandler(event) {
    // if (this.props.location.key !== event.location.key) {
    // console.log('eve', event);

    const nextPageResources =
      this.props.loader.loadPage(
        event.detail.pathname,
        nextPageResources => this.setState({ nextPageResources }),
      ) || {};

    if (!this.props.location.pathname === '/') {
      this.setState({ exiting: true, nextPageResources });
    } else {
      this.setState({ exiting: false, nextPageResources });
    }
  }

  componentDidMount() {
    window.addEventListener(historyExitingEventType, this.listenerHandler);
  }

  componentWillUnmount() {
    window.removeEventListener(
      historyExitingEventType,
      this.listenerHandler,
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.key !== nextProps.location.key) {
      this.setState({ exiting: false, nextPageResources: {} });
    }
  }

  render() {
    const transitionProps = {
      timeout: {
        enter: 0,
        exit: timeout,
      },
      appear: true,
      in: !this.state.exiting,
      key: this.props.location.key,
    };
    return (
      <Transition {...transitionProps}>
        {status =>
          createElement(this.props.pageResources.component, {
            ...this.props,
            ...this.props.pageResources.json,
            transition: {
              status,
              timeout,
              style: getTransitionStyle({ status, timeout }),
              nextPageResources: this.state.nextPageResources,
            },
          })
        }
      </Transition>
    );
  }
}

// eslint-disable-next-line react/display-name
export const replaceComponentRenderer = ({ props, loader }) => {
  if (props.layout) {
    return undefined;
  }
  return createElement(ReplaceComponentRenderer, { ...props, loader });
};
