import React from 'react';
// import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import '../layouts/all.sass';
import '../layouts/projects.sass';
import '../layouts/music.sass';
import '../layouts/about.sass';
import '../layouts/foldingcube.sass';

import Navbar from './Navbar.js';

const Layout = ({ children }) => (
  <main className="sw-main">
    <div className="container">
      {/* <br />
      <h1 style={{ marginLeft: '30px' }}> Vacent Domain - Status 400</h1> */}
      <Helmet title="Dave B." />
      <Navbar />
      <div>{children}</div>
      {/* <footer className="sw-footer">
        <img className="sw-pic" src="img/bg.png" alt="hi" />
      </footer> */}
    </div>
  </main>
);

export default Layout;
