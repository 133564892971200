import React from 'react';
import { Link } from 'gatsby';

import { FaGithub } from 'react-icons/fa';
// import { FaTwitter } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
// import { FaCodepen } from 'react-icons/fa';

// import logo from '../img/logo.svg';

const Navbar = () => (
  <nav className="navbar is-transparent">
    <div className="container">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <p className="my-name post-heading">
            Dave Barthly | <i className="my-handle">@satiewaltz</i>
          </p>
        </Link>
      </div>
      <div className="navbar-start">
        <Link className="navbar-item" to="/projects">
          Projects
        </Link>
        {/* <Link className="navbar-item" to="/music">
          Music
        </Link> */}
        <Link className="navbar-item" to="/about">
          About
        </Link>
        {/*
        <a className="navbar-item" href="https://api.theweb.rocks">
          Reactionary API
        </a> */}
      </div>

      <div className="navbar-end">
        <a
          className="navbar-item"
          href="https://github.com/satiewaltz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="icon">
            <FaGithub />
          </span>
        </a>
        {/* <a
          className="navbar-item"
          href="https://codepen.io/satiewaltz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="icon">
            <FaCodepen />
          </span>
        </a> */}
        {/* <a
          className="navbar-item"
          href="https://twitter.com/satiewaltz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="icon">
            <FaTwitter />
          </span>
        </a> */}
        <a
          className="navbar-item"
          href="https://linkedin.com/in/satiewaltz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="icon">
            <FaLinkedin />
          </span>
        </a>
        <a
          className="navbar-item"
          href="mailto:davebarthly@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="icon">
            <FaEnvelope />
          </span>
        </a>
      </div>
    </div>
  </nav>
);

export default Navbar;
